import React, {Component} from "react";

import "./line-divider.component.scss";

export class LineDividerComponent extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="ee-line-divider-component" />
        );
    }

}
